.animation-loading {
  animation: animation-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes animation-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
