@import url('./stylesheet.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html {
    font-family: Tahoma, sans-serif;
    font-weight: normal;
    font-style: normal;
  }

  html * {
    box-sizing: border-box;
  }
}

/* input,
input:focus {
  outline: none;
} */

/* textarea,
textarea:focus {
  outline: none;
} */

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
} */

button {
  outline: 0;
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}